/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrow-right': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.617 4.999a.85.85 0 011.202 0l3.182 3.182a.85.85 0 010 1.202l-3.182 3.182a.85.85 0 01-1.202-1.202l1.73-1.731H1.4a.85.85 0 010-1.7h12.948l-1.731-1.731a.85.85 0 010-1.202z" _fill="#fff"/>'
  }
})
